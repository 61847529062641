/*
 * @Author: 顾喜斌 xibin_gu@163.com
 * @Date: 2023-01-30 11:03:46
 * @LastEditors: 顾喜斌 xibin_gu@163.com
 * @LastEditTime: 2023-03-15 10:27:02
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import router_modular from '@/router/router_modular/index.js'
import { isWexin, isAlipay, getQueryStringApp } from '@/utils/utils'
import { getCodeAndRedirect, whetherOpenInWxMiniApp, getAliMiniAppId } from '@/api/weixin'
import { setLocalStorage, getLocalStorage, removeLocalStorage } from '@/utils/storage'
import { jumpReplace } from '@/utils/jump'
Vue.use(VueRouter)

const routes = [
    ...router_modular
]

const router = new VueRouter({
    mode:'history',
    routes
})
//全局路由守卫
router.beforeEach(async (to,from,next)=>{
    if (isAlipay()) {
        const appId = await getAliMiniAppId()  // 小程序appid
        if (appId) {
            const page = 'pages/index/index' // 跳转页面(注意：pages前面不用/)
            const plateUrl = window.location.href.replace(new RegExp("&", "g"), '1234567890')
            const query = `url=${plateUrl}` // 查询参数
            const platformUrl = `alipays://platformapi/startapp?appId=${appId}&page=${page}&query=${query}`
            // 跳转地址需要 encodeURIComponent
            const encodePlatformUrl = encodeURIComponent(platformUrl)
            // 完整地址(浏览器中打开)
            const href = `https://ds.alipay.com/?scheme=${encodePlatformUrl}`
            location.href = href
        }
    }else if (!to.meta.isSpecialPage) {  //  to.meta.isSpecial true 为与用户无关特殊页面
        let whetherResul = ''
        if (to.name === 'bindPallet') {
            whetherResul = await whetherOpenInWxMiniApp()
        }
        if (whetherResul && whetherResul !== 'error') {
            console.log(43,  window.location.href)
            const plateUrl = window.location.href.replace(new RegExp("&", "g"), '1234567890')
            setLocalStorage('miniWechatPlateUrl', plateUrl)
            jumpReplace(location.origin +`/openMiniWechat`)
        } else if (to.name === 'openMiniWechat') {
            next()
        } else {
            // 截取租户ID和类型
            const tenantId = getQueryStringApp('tenantId') || getLocalStorage('tenantId') || ''
            const trdType = getQueryStringApp('trdType') || getLocalStorage('trdType') || ''
            const token = getQueryStringApp('token') || getLocalStorage('token') || ''
            const openId = getQueryStringApp('openId') || getLocalStorage('openId') || ''
            // 截取路径 缓存token openId tenantId trdType
            openId && setLocalStorage('openId', openId)
            token && setLocalStorage('token', token)
            tenantId && setLocalStorage('tenantId', tenantId)
            trdType && setLocalStorage('trdType', trdType)
            tenantId && localStorage.setItem('commonTenantId', tenantId)
            trdType && localStorage.setItem('commonTrdType', trdType)
            if (!isWexin() && to.name !== 'notWeixin') {
                // 不是微信内部打开
                next({ name: 'notWeixin' })
            } else if (to.meta.isInWhileList) {
                next()
            } else if (!getLocalStorage('token')) {  // 未登录
                const hrefUrl = window.location.href
                if (!hrefUrl.includes('/bindPallet') && !hrefUrl.includes('/tray') ) {
                    removeLocalStorage('bindTrayUrl')
                }
                if (hrefUrl.includes('trayNo=')) {
                    setLocalStorage('bindTrayUrl', hrefUrl)
                    setLocalStorage('currentAppUrl', location.origin +'/tray?trdType=' + getLocalStorage('trdType') + '&tenantId=' +  getLocalStorage('tenantId'))
                } else if (
                    !hrefUrl.includes('/register') &&
                    !hrefUrl.includes('/login') &&
                    !hrefUrl.includes('/notFollowed') &&
                    !hrefUrl.includes('/notTokenPage')
                ) {
                    setLocalStorage('currentAppUrl', hrefUrl)
                }
                if (!getLocalStorage('openId')) {
                    getCodeAndRedirect({}).then(res => {
                        jumpReplace(res)
                    })
                } else {
                    next()
                }
            } else {
                // 其他情况
                next()
            }
        }
    } else {
        if (!isWexin() && to.name !== 'payNotWeixin') {
            // 不是微信内部打开
            next({ name: 'payNotWeixin' })
        } else {
            next()
        }
    }
})
router.afterEach((to)=>{
    document.title = to.meta.title
})
export default router
