/*
 * @Author: 顾喜斌 xibin_gu@163.com
 * @Date: 2023-01-17 14:46:18
 * @LastEditors: 顾喜斌 xibin_gu@163.com
 * @LastEditTime: 2023-03-21 15:05:34
 */
import {request} from '../utils/request'

/**
 * 获取用户信息
 */
export function USER_INFO(data={}) {
    return request({
        url: '/h5/frontUser/getUserInfo',
        method: 'post',
        data
    })
}
/**
 * 获取用户余额
 */
export function USER_BALANCE(data={}) {
    return request({
        url: '/h5/frontUser/getUserBalance',
        method: 'post',
        data
    })
}
/**
 * 更换头像
 */
export function headImageUpdate(data={}) {
    return request({
        url: '/h5/frontUser/portrait/update',
        method: 'post',
        data
    })
}
/**
 * 修改昵称
 */
export function nickNameUpdate(data={}) {
    return request({
        url: '/h5/frontUser/nickname/update',
        method: 'post',
        data
    })
}
/**
 * 上传人脸照片
 */
 export function faceImageUpdate(data={}) {
    return request({
        url: '/h5/frontUser/faceImg/update',
        method: 'post',
        data
    })
}
/**
 * 解除账号绑定
 */
export function unbindUser(data={}) {
    return request({
        url: '/h5/frontUser/unbind',
        method: 'post',
        data
    })
}
/**
 * 建议与反馈
 */
export function submitFeedback(data={}) {
    return request({
        url: '/h5/feedback/submit',
        method: 'post',
        data
    })
}
/**
 * 报餐_获取开启了报餐功能的商户列表
 */
export function mealReportMerchantList(params={}) {
    return request({
        url: '/h5/mealApply/merchantList',
        method: 'get',
        params
    })
}
/**
 * 报餐_获取商户指定日期是否开启报餐
 */
export function mealReportWhetherCanApply(params={}) {
    return request({
        url: '/h5/mealApply/whetherCanApply',
        method: 'get',
        params
    })
}
/**
 * 报餐_获取指定日期报餐信息
 */
export function mealReportApplyInfo(params={}) {
    return request({
        url: '/h5/mealApply/applyInfo',
        method: 'get',
        params
    })
}
/**
 * 报餐_获取可报餐信息列表（新_批量报餐）
 */
export function mealReportApplyInfos(params={}) {
    return request({
        url: '/h5/mealApply/applyInfos',
        method: 'get',
        params
    })
}
/**
 * 报餐_批量保存报餐信息（新_批量报餐）
 */
export function saveMealReportApplyInfos (data={}) {
    return request({
        url: '/h5/mealApply/saveInfos',
        method: 'post',
        data
    })
}
/**
 * 报餐_保存报餐信息
 */
export function mealReportApplySave(data={}) {
    return request({
        url: '/h5/mealApply/save',
        method: 'post',
        data
    })
}
/**
 * 餐次推荐摄入列表
 */
export function mealRecommendIntakeData(data={}) {
    return request({
        url: '/h5/nutrition/recommendation/list',
        method: 'post',
        data
    })
}
/**
 * 根据日期查询当日营养摄入
 */
export function queryNutritionByDate(data={}) {
    return request({
        url: '/h5/nutrition/queryByDate',
        method: 'post',
        data
    })
}
/**
 * 推荐摄入总能量
 */
export function totalRecommendIntakeData(data={}) {
    return request({
        url: '/h5/nutrition/recommendation/totalEnergy',
        method: 'post',
        data
    })
}
/**
 * 根据订单号查询营养摄入
 */
export function queryNutritionBySn(data={}) {
    return request({
        url: '/h5/nutrition/queryBySn',
        method: 'post',
        data
    })
}
/**
 * 获取我的服务列表
 */
export function getAllServices(data={}) {
    return request({
        url: '/h5/frontUser/getMyFunctions',
        method: 'post',
        data
    })
}
/**
 * 获取我的钱包服务列表
 */
export function getMyWallet(data={}) {
    return request({
        url: '/h5/frontUser/getMyWalletFunctions',
        method: 'post',
        data
    })
}
/**
 * 生成内部系统付款码
 */
export function generatePayCode(params={}) {
    return request({
        url: '/h5/frontUser/generatePayCode',
        method: 'get',
        params
    })
}
/**
 * 查询内部系统付款码是否已失效
 */
export function isRefreshQrCode(params={}) {
    return request({
        url: '/h5/frontUser/getPayCodeInvalid',
        method: 'get',
        params
    })
}
/**
 * 一周菜单_获取菜单图片列表
 */
export function foodMenuList(data={}) {
    return request({
        url: '/h5/foodMenuPic/list',
        method: 'post',
        data
    })
}
/**
 * 绑定卡用户信息_获取所有部门下拉列表
 */
export function allDeptList(data={}) {
    return request({
        url: '/h5/frontUserBindCardUser/getAllDeptList',
        method: 'post',
        data
    })
}
/**
 * 绑定卡用户信息_根据部门、昵称获取可绑定的卡用户信息列表
 */
export function canBindCardUserList(data={}) {
    return request({
        url: '/h5/frontUserBindCardUser/getCanBindCardUserInfoList',
        method: 'post',
        data
    })
}
/**
 * 绑定卡用户信息_绑定卡用户信息
 */
export function bindCardUserInfo(data={}) {
    return request({
        url: '/h5/frontUserBindCardUser/bindCardUserInfo',
        method: 'post',
        data
    })
}
/**
 * 绑定卡用户信息_获取已绑定卡号
 */
export function boundCardNo(data={}) {
    return request({
        url: '/h5/frontUserBindCardUser/getBoundCardNo',
        method: 'post',
        data
    })
}
/**
 * 报餐_查询报餐记录（新）
 */
export function getApplyHistory(data={}) {
    return request({
        url: '/h5/mealApply/applyHistory',
        method: 'post',
        data
    })
}